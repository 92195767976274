var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({staticClass:"btn btn-primary font-weight-bolder",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.refresh($event)}}},on),[_vm._v(" "+_vm._s(_vm.$t("stopwords.dialog.predefined.action.add_set")))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("stopwords.dialog.predefined.title")))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"alert fade alert-danger",class:{ show: _vm.errors.length },attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-select',{ref:"f_set",attrs:{"label":this.$t('stopwords.dialog.predefined.fields.type.label'),"item-text":"name","item-value":"value","rules":[
                  function (v) { return !!v ||
                    this$1.$t('stopwords.dialog.predefined.fields.type.rule'); }
                ],"items":_vm.predefinedSets,"required":""},model:{value:(_vm.set),callback:function ($$v) {_vm.set=$$v},expression:"set"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(this.$t("common.close")))]),_c('v-spacer'),_c('v-btn',{ref:"submit",attrs:{"color":"primary dark large"},on:{"click":_vm.save}},[_vm._v(_vm._s(this.$t("common.save"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }