<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click.prevent="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
      >
        {{ $t("stopwords.dialog.predefined.action.add_set") }}</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">{{
            $t("stopwords.dialog.predefined.title")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="set"
                  :label="
                    this.$t('stopwords.dialog.predefined.fields.type.label')
                  "
                  ref="f_set"
                  item-text="name"
                  item-value="value"
                  :rules="[
                    v =>
                      !!v ||
                      this.$t('stopwords.dialog.predefined.fields.type.rule')
                  ]"
                  :items="predefinedSets"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="dialog = false">{{
            this.$t("common.close")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >{{ this.$t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  ADD_SET_STOPWORDS,
  PULL_STOPWORDS,
  REFRESH
} from "@/core/services/store/stopwords.module";

export default {
  data: () => ({
    valid: true,
    dialog: false,
    model: [],
    set: ""
  }),
  methods: {
    refresh: function() {
      this.term = "";
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(ADD_SET_STOPWORDS, this.set).then(() => {
          this.dialog = false;
          this.$store.dispatch(REFRESH);
          this.$store.dispatch(PULL_STOPWORDS);
        });
      }
    }
  },
  validations: {
    form: {
      name: { required }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.stopwords.errors,
      message: state => state.stopwords.message,
      hasMessage: state => state.stopwords.hasMessage,
      predefinedSets: state => state.stopwords.predefined_sets
    })
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
  }
};
</script>
