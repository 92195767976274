<template>
  <v-btn @click="reloadElastic" :loading="this.loadingElastic" color="warning">
    {{ $t("tools.elastic_reload.action.generate") }}
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import { RELOAD_ELASTIC } from "@/core/services/store/elastic.module";

export default {
  computed: {
    ...mapState({
      loadingElastic: state => state.elastic.loading
    })
  },
  methods: {
    reloadElastic() {
      Swal.fire({
        title: this.$t("tools.elastic_reload.title"),
        text: this.$t("tools.elastic_reload.text"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("tools.elastic_reload.confirm"),
        cancelButtonText: this.$t("tools.elastic_reload.cancel")
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(RELOAD_ELASTIC).then(() => {
            Swal.fire(this.$t("tools.elastic_reload.done"), "", "success");
          });
        }
      });
    }
  }
};
</script>
